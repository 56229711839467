<template>
  <div class="notifications-filters" v-click-outside="closeAction">
    <button
      class="btn sm primary-light"
      :class="{ active: isOpen }"
      type="button"
      :disabled="disabled"
      :data-btn="uniqId"
      @click.prevent="toggleAction"
    >
      <BaseIcon class="ic-16 primary" icon="filter" />
      Фильтры
      <small v-if="countFilters" class="marker secondary">{{ countFilters }}</small>
    </button>

    <div v-if="isOpen" class="notifications-filters__body box" :data-body="uniqId">
      <div class="p-20 grid ggap-20">
        <!-- Is Read -->
        <div class="grid gtc-3">
          <button
            @click.prevent.stop="delete propValue.is_read"
            class="btn transparent-grey sm pl-10 jc-c"
            :class="{ active: isReadEmpty }"
          >
            <BaseIcon class="ic-16 grey" :icon="isReadEmpty ? 'check-circle' : 'check'" />
            Любые
          </button>
          <button
            @click.prevent.stop="propValue.is_read = true"
            class="btn transparent-grey sm pl-10 jc-c"
            :class="{ active: isRead }"
          >
            <BaseIcon class="ic-16 grey" :icon="isRead ? 'check-circle' : 'check'" />
            Прочитанные
          </button>
          <button
            @click.prevent.stop="propValue.is_read = false"
            class="btn transparent-grey sm pl-10 jc-c"
            :class="{ active: isReadNo }"
          >
            <BaseIcon class="ic-16 grey" :icon="isReadNo ? 'check-circle' : 'check'" />
            Не прочитанные
          </button>
        </div>

        <!-- Author -->
        <FormAutocompleteSearch
          class="f-1"
          label="Автор уведомления"
          placeholder="Выберите автора уведомления"
          :options="optionsUsers"
          :isLoad="isLoad"
          :isMultiple="true"
          @search="searchUsers($event)"
          v-model="propValue.users"
        >
          <template #tag="{ item }">
            <ItemUser :item="item" @remove="removeUserItem" />
          </template>
        </FormAutocompleteSearch>

        <!-- Номер заявки -->
        <FormAutocompleteSearch
          class="f-1"
          label="Номер заявки"
          placeholder="Выберите номер заявки"
          :options="optionsOrders"
          :isLoad="isLoad"
          :isMultiple="true"
          @search="searchOrder($event)"
          v-model="propValue.lawyers"
        />
      </div>

      <div class="notifications-filters__foot flex ai-c jc-sb ggap-20 pl-20 pr-20 pt-10 pb-10">
        <button class="btn sm cancel" :disabled="isEmpty" @click.prevent="resetFilter">Сбросить</button>
        <button class="btn sm green" :disabled="isNoChanges" @click.prevent="saveFilter">Применить</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { isEqual, cloneDeep } from 'lodash'
import { useStore } from 'vuex'
import { defineEmits, defineProps, computed, toRefs, ref } from 'vue'
import { BaseIcon, FormAutocompleteSearch, ItemUser } from '@/components'
import { calcCoords } from '@/plugins'

// Emits
const emits = defineEmits(['submitFilters', 'update:modelValue'])

// Props
const props = defineProps(['disabled', 'oldFilters', 'modelValue'])

// Data
const { disabled, oldFilters, modelValue } = toRefs(props)
const store = useStore()
const isLoad = ref(false)
const isOpen = ref(false)
const direction = 'ltr'
const uniqId = 'ddown-filter'
// const orderList = ref([])

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
const optionsUsers = computed(() => store.getters.users || [])
const optionsOrders = computed(() => store.getters['helpers/orders'] || [])
const isRead = computed(() => typeof propValue.value['is_read'] !== 'undefined' && propValue.value?.is_read)
const isReadNo = computed(() => typeof propValue.value['is_read'] !== 'undefined' && !propValue.value?.is_read)
const isReadEmpty = computed(() => typeof propValue.value['is_read'] === 'undefined')
const isEmpty = computed(() => {
  const res = []

  for (const key in propValue.value) {
    if (Object.hasOwnProperty.call(propValue.value, key)) {
      const el = propValue.value[key]
      if (Array.isArray(el)) res.push(!!el.length)
      else res.push(Object.hasOwnProperty.call(propValue.value, key))
    }
  }

  return !res.some((el) => el === true)
})
const isNoChanges = computed(() => isEqual(propValue.value, oldFilters.value))
const countFilters = computed(() => {
  const res = []

  for (const key in propValue.value) {
    if (Object.hasOwnProperty.call(propValue.value, key)) {
      const el = propValue.value[key]
      if (Array.isArray(el)) res.push(!!el.length)
      else res.push(Object.hasOwnProperty.call(propValue.value, key))
    }
  }

  return res.filter((el) => el).length
})

// Methods:users
async function searchUsers(searchText) {
  isLoad.value = true
  await store.dispatch('GET_USERS', searchText)
  isLoad.value = false
}
function removeUserItem(id) {
  const index = propValue.value?.users?.findIndex((el) => el.id === id)
  propValue.value?.users?.splice(index, 1)
}

// Methods:orders
async function searchOrder(searchText) {
  isLoad.value = true
  await store.dispatch('helpers/SEARCH_ORDERS', searchText)
  isLoad.value = false
}

// Methods
async function toggleAction() {
  isOpen.value = !isOpen.value
  if (isOpen.value) calcCoords(uniqId, direction)
}
function closeAction() {
  isOpen.value = false
  if (!isNoChanges.value) {
    propValue.value = cloneDeep(oldFilters.value)
  }
}
function resetFilter() {
  propValue.value = {
    lawyers: [],
    users: []
  }
  isOpen.value = false
  // if (isNoChanges.value) emits('submitFilters')
  emits('submitFilters')
}
function saveFilter() {
  isOpen.value = false
  emits('submitFilters')
}
</script>

<style lang="scss" scoped>
.notifications-filters {
  &__body {
    position: fixed;
    z-index: 3;
    box-shadow: var(--box-shadow);
    width: 540px;
  }

  &__foot {
    border-top: var(--b1);
  }
}
</style>
